import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { connect } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { userLogout } from "../../redux/actions/UserActions";
import ListComponent from "./components/ListComponent";
import ThumbnailComponent from "./components/ThumbnailComponent";

const Videos = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const monthFormat = "YYYY/MM";
  const [videoList, setVideoList] = useState(null);
  const [originalVideoList, setOriginalVideoList] = useState(null);
  const [videoUploader, setVideoUploader] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [selectedType, setSelectedType] = useState(0);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  //   console.log("base url >> ", basurl);

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = list.filter((item) => {
        console.log("agar.....", item);
        return (
          moment(item.LastModified).toDate() <= date2 &&
          moment(item.LastModified).toDate() >= date1
        );
      });
      setList(newArr);
    } else {
      getVideosList();
    }

    // console.log("get date from range >>>", newArr)
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  const getVideosList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/video-list`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          setVideoList(videoList.data);
          setOriginalVideoList(videoList.data);
          // sort by date array
          const sortedArr =
            videoList.data.Contents.length > 0
              ? videoList.data.Contents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.LastModified) - new Date(a.LastModified);
                })
              : [];
          setList(sortedArr);
        }
        // console.log("videoList>> ", videoList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };

  useEffect(() => {
    getVideosList();
  }, []);

  // console.log("all list>>", list);

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new" },
    region: "ap-south-1",
  });

  const handleDelete = (key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this file ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const paramm = {
          Bucket: "lms-videos-bucket-prepzone-new",
          Key: key,
        };
        myBucket.deleteObject(paramm, (err, data) => {
          if (err) {
            console.log(err);
          } else {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            getVideosList();
          }
        });
      }
    });
  };

  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setVideoProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt);
        getVideosList();
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          setVideoUploader(false);
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Woah!! File Uploaded Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // handleTypes
  const handleTypes = (evt) => {
    const type = evt.target.value;
    setSelectedType(type);
    console.log("type >> ", type);
  };

  // for search filter
  const filteredData = list.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (val.Key.toLowerCase().includes(searchInput.toLowerCase())) {
      return val;
    }
  });

  // console.log("filteredData >> ", filteredData);

  useEffect(() => {
    if (selectedType == 1) {
      const videos = videoList?.Contents.filter((video) => {
        const extention = video.Key.split(".").pop();
        return (
          extention === "mp4" ||
          extention === "avi" ||
          extention === "mkv" ||
          extention === "flv"
        );
      });

      setList(videos);
    } else if (selectedType == 3) {
      const images = videoList?.Contents.filter((imgs) => {
        const extention = imgs.Key.split(".").pop();
        return (
          extention === "jpg" ||
          extention === "png" ||
          extention === "gif" ||
          extention === "svg"
        );
      });

      setList(images);
    } else if (selectedType == 2) {
      const pdfs = videoList?.Contents.filter((pdf) => {
        const extention = pdf.Key.split(".").pop();
        return extention === "pdf";
      });

      setList(pdfs);
    } else if (selectedType == 4) {
      const audios = videoList?.Contents.filter((audio) => {
        const extention = audio.Key.split(".").pop();
        return extention === "mp3";
      });

      setList(audios);
    } else {
      getVideosList();
    }
  }, [selectedType]);

  return (
    <MainContainer>
      <div className="cards">
        <div className="card-headers">
          <div className="row">
            <div className="col-sm-6">
              <div className="d-flex align-items-center">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Media
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => setVideoUploader(!videoUploader)}
                >
                  <i className="fas fa-plus"></i> Add Media
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          {videoProgress > 0 && videoProgress < 99 ? (
            <div
              className={"progress_wrapper"}
              style={{ marginBottom: "10px" }}
            >
              <div className="progress_wrap">
                <CircularProgressbar
                  value={videoProgress}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#33b3f2",
                  })}
                />
              </div>
            </div>
          ) : null}
          {videoUploader && (
            <div className={"section_video_uploader"}>
              <Dropzone
                onDrop={(acceptedFiles) => uploadVideoHandler(acceptedFiles)}
                // accept={"video/mp4, video/avi, video/mkv, video/flv"}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={"dropzone_wrapper"}>
                    <span
                      className="close_icon_dropzone"
                      onClick={() => setVideoUploader(false)}
                    >
                      <i className="ri-close-line"></i>
                    </span>
                    <div {...getRootProps()} className={"dropZone"}>
                      <input {...getInputProps()} />
                      <div className="d-flex flex-column">
                        <p>Drop files to upload</p>
                        <p className="text-center mt-2 mb-2">or</p>
                        <button type="button" className="btn btn-primary">
                          <i className="ri-upload-line"></i> Upload
                        </button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )}
          <div className="filter_options">
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-sm-4">
                    <select
                      className="form-control"
                      onChange={(e) => handleTypes(e)}
                      value={selectedType}
                    >
                      <option value={0}>All Media</option>
                      {/* <option value={1}>Videos</option> */}
                      <option value={2}>PDF</option>
                      <option value={3}>Images</option>
                      {/* <option value={4}>Audio & Music</option> */}
                    </select>
                  </div>
                  <div className="col-sm-1 d-flex justify-content-center">
                    <span
                      className="layout_change_icon"
                      onClick={() => setIsGridView(!isGridView)}
                    >
                      {isGridView ? (
                        <i className="ri-layout-grid-line"></i>
                      ) : (
                        <i className="ri-list-check-2"></i>
                      )}
                    </span>
                  </div>
                  <div className="col-sm-7">
                    <RangePicker
                      format={dateFormat}
                      className={"form-control custom_range_picker"}
                      onChange={handleRange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {videoList !== null ? (
            <>
              {isGridView ? (
                <div className="layout_thumbnail_view">
                  {filteredData.map((item) => (
                    <ThumbnailComponent
                      data={item}
                      videoListName={videoList.Name}
                      handleDelete={handleDelete}
                    />
                  ))}
                </div>
              ) : (
                <div className="layout_list_view">
                  {filteredData.map((item) => (
                    <ListComponent
                      data={item}
                      videoListName={videoList.Name}
                      handleDelete={handleDelete}
                    />
                  ))}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Videos);
